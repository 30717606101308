.footer {
    width: 100%;
    background-color: #f4f4f4;
    color: #333;
    font-family: 'Arial', sans-serif;
    padding-left: 70px;
}

.footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo img {
    width: 50px;
    height: auto;
    margin-right: 10px;
}

.company-name {
    font-size: 20px;
}

.blue-text {
    color: #0CA9E2;
}

.green-text {
    color: #5FBD4A;
}

@media (max-width: 899px) {
    .footer {
        padding-left: 0;
    }
}

.footer-navigation {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
}

.footer-nav-item {
    color: #333;
    cursor: pointer;
    margin: 0;
}

.footer-nav-item:hover {
    color: #039be5;
}

.footer-base {
    background-color: #e8e8e8;
    padding: 1px;
    text-align: center;
}

.copyright {
    font-size: 14px;
    color: #999;
}

.subscribe-input {
    display: flex;
    justify-content: center;
    align-items: center;
    /*margin: 20px 0;*/
}

.subscribe-field {
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-right: 10px;
    flex-grow: 1;
}

.subscribe-btn {
    background-color: #38a169;
    border: none;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
}

